import React from "react";
import { Box, Label, Text, Swatch } from "@blasterjs/core";
import { StacItem } from "../stac/StacItem";
import { map, fromNullable, getOrElse } from "fp-ts/es6/Option";

interface LegendProps {
  item: StacItem;
  showSlider: boolean;
  splitToText: (b: boolean) => string;
}

function Legend({ item, showSlider, splitToText }: LegendProps) {
  return getOrElse(() => <></>)(
    map((legend: [string, string][]) => (
      <Box display="flex" flexDirection="column" alignItems="left" justifyContent="space-between">
        <Label fontWeight="bold" mb={1}>
          {splitToText(showSlider)}
        </Label>
        {legend.map(([className, color]: [string, string]) => (
          <Box>
            <Box alignItems="left" flexDirection="row" display="flex" my={1}>
              <Swatch color={color} size="1em" mr={"1em"} />
              <Text>{className}</Text>
            </Box>
          </Box>
        ))}
      </Box>
    ))(fromNullable(item.properties["label:legend"]))
  );
}

export default Legend;
