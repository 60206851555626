import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Brand,
  Checkbox,
  Button,
  Divider,
  Icon,
  Label,
  Select,
  TextInput,
  RangeInput
} from "@blasterjs/core";
import Field from "./Field";
import Legend from "./Legend";

import { getCollection, getCollectionItems } from "../http/stac";
import { MapBands } from "../model";
import { some, option, fromNullable, getOrElse, map, Option } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";
import { StacCollection } from "../stac/StacCollection";
import { StacItemFeatureCollection } from "../stac/StacItemFeatureCollection";
import { StacItem, getCogAssets, StacItemAssetWrapper } from "../stac/StacItem";
import { StacLink } from "../stac/StacLink";
import { Band } from "../stac/Band";

const StyledHeader = styled(Box)`
  transition: all 250ms;
`;

const StyledSidebar = styled(Box)`
  transition: all 250ms;
  ${props =>
    props.isClosed &&
    `
      margin-left: -32rem;

      ${StyledHeader} {
        transform: translateX(6.4rem);
        padding-right: 0.8rem;
        box-shadow: inherit;
      }
    `}
`;

interface SidebarProps {
  aLayerSelect: (item: StacItem) => void;
  bLayerSelect: (item: StacItem) => void;
  selectedItem: (item: StacItem) => void;
  aLayerOpacity: (i: number) => void;
  bLayerOpacity: (i: number) => void;
  showSlider: boolean;
  setShowSlider: (b: boolean) => void;
  aLayerItem: Option<StacItem>;
  bLayerItem: Option<StacItem>;
  setMapBands: (mapBands: MapBands) => void;
  setSourceAssetId: (sourceAssetId: Option<string>) => void;
}

function Sidebar({
  aLayerSelect,
  bLayerSelect,
  selectedItem,
  aLayerOpacity,
  bLayerOpacity,
  showSlider,
  setShowSlider,
  aLayerItem,
  bLayerItem,
  setMapBands,
  setSourceAssetId
}: SidebarProps) {
  const [redBand, setRedBand] = useState(3);
  const [blueBand, setBlueBand] = useState(1);
  const [greenBand, setGreenBand] = useState(2);
  const [isOpen, setIsOpen] = useState(true);
  const [collection, setCollection] = useState(option.zero<StacCollection>());
  const [collectionUrl, setCollectionUrl] = useState("");
  const [collectionItems, setCollectionItems] = useState(option.zero<StacItemFeatureCollection>());
  const [viewableItems, setViewableItems] = useState([] as StacItem[]);
  const [sourceItems, setSourceImages] = useState([] as StacItem[]);
  const [sourceAssets, setSourceAssets] = useState([] as StacItemAssetWrapper[]);
  const [showSelectAsset, setShowSelectAsset] = useState(false);
  const [showBandSelect, setShowBandSelect] = useState(false);
  const [bands, setBands] = useState([] as Band[]);
  const [sourceAsset, setSourceAsset] = useState<StacItemAssetWrapper | null>(null);
  const [layer1Visible, setLayer1Visible] = useState(true);
  const [layer2Visible, setLayer2Visible] = useState(true);
  const [layer1Opacity, setLayer1Opacity] = useState(100);
  const [layer2Opacity, setLayer2Opacity] = useState(100);

  const visibilityButton = (
    <Button appearance="minimal" p={0} onClick={() => setIsOpen(!isOpen)}>
      <Icon name={isOpen ? "arrowLeft" : "arrowRight"} color="black" size="2.4rem" />
    </Button>
  );

  useEffect(() => {
    let isCurrent = true;
    const fetchData = () =>
      getCollection(collectionUrl).then(
        response => isCurrent && setCollection(some(response.data))
      );
    fetchData();
    return () => {
      isCurrent = false;
    };
  }, [collectionUrl, setCollection]);

  useEffect(() => {
    if (sourceAsset && sourceAsset.asset.bands && sourceAsset.asset.bands.length > 1) {
      // Try to set default values by comparing common names
      let checkForDefaultValue = (name: string) => (band: Band) =>
        band.common_name ? band.common_name.toLowerCase() === name : false;

      let bands = sourceAsset.asset.bands;
      let redBand = bands.findIndex(checkForDefaultValue("red"));
      let greenBand = bands.findIndex(checkForDefaultValue("green"));
      let blueBand = bands.findIndex(checkForDefaultValue("blue"));
      if (blueBand > -1) {
        setBlueBand(blueBand);
      }
      if (redBand > -1) {
        setRedBand(redBand);
      }
      if (greenBand > -1) {
        setGreenBand(greenBand);
      }
      setShowBandSelect(true);
    }
  }, [sourceAsset]);

  function getSourceItems(collection: StacItemFeatureCollection): Array<StacItem> {
    return collection.features.filter(item => {
      let sourceLinks = item.links.filter(item => item.rel === "source");
      return sourceLinks.length < 1;
    });
  }

  function bandSelector(
    label: string,
    id: string,
    setter: (i: number) => void,
    defaultValue: string
  ) {
    return (
      <Field
        label={label}
        showLabel={true}
        htmlFor={id}
        visible={true}
        showEye={false}
        onVisibilityChange={(b: boolean) => {}}
        mb={4}
      >
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setter(+e.target.value)}
          id={id}
          defaultValue={defaultValue}
        >
          <option>Select a Red Band</option>
          {bands.map((item, index) => (
            <option key={index} value={index}>
              {item.common_name || item.name}
            </option>
          ))}
        </Select>
      </Field>
    );
  }

  function onAssetSelect(assetIndex: string) {
    let asset = sourceAssets[+assetIndex];
    let bands = asset.asset.bands || ([] as Band[]);
    if (bands.length === 0) {
      setShowBandSelect(false);
      setRedBand(3);
      setGreenBand(2);
      setBlueBand(1);
    }
    setBands(bands);
    setSourceAsset(asset);
    setSourceAssetId(some(asset.id));
  }

  /**
   * When a source item is selected, we find all the stac items to let
   * a user choose one in the drop down to visualize
   **/
  function onSourceSelect(sourceIndex: string) {
    let source = sourceItems[+sourceIndex];
    setSourceAssets(getCogAssets(source));
    selectedItem(source);
    let selfLink = fromNullable(source.links.find(item => item.rel === "self"));

    const findViewableItems = (link: StacLink) => (collectionItems: StacItemFeatureCollection) => {
      let labelItems = collectionItems.features.filter(item => {
        let sourceLink = fromNullable(item.links.find(item => item.rel === "source"));
        const hasSourceLink = option.map(sourceLink, l => l.href === link.href);
        return pipe(
          hasSourceLink,
          getOrElse((): Boolean => false)
        );
      });
      // Put the source in the array because it can also
      // be visualized by the user
      setViewableItems(labelItems);
    };

    option.ap(option.map(selfLink, findViewableItems), collectionItems);
  }

  function addRangeInput(setFunction: (value: React.SetStateAction<number>) => void) {
    return (
      <RangeInput
        mb="1.2rem"
        min={0}
        max={100}
        defaultValue={100}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFunction(+e.target.value)}
      />
    );
  }

  useEffect(() => {
    if (sourceAssets.length > 1) {
      setShowSelectAsset(true);
    } else if (sourceAssets.length === 1) {
      let asset = sourceAssets[0];
      let bands = asset.asset.bands || ([] as Band[]);
      setBands(bands);
      setSourceAsset(asset);
    }
  }, [showSelectAsset, sourceAssets]);

  useEffect(() => {
    let opacityValue = layer1Visible ? layer1Opacity : 0;
    aLayerOpacity(opacityValue);
  }, [aLayerOpacity, layer1Visible, layer1Opacity]);

  useEffect(() => {
    setMapBands({ redBand: redBand, greenBand: greenBand, blueBand: blueBand });
  }, [redBand, greenBand, blueBand, setMapBands]);

  useEffect(() => {
    let opacityValue = layer2Visible ? layer2Opacity : 0;
    bLayerOpacity(opacityValue);
  }, [bLayerOpacity, layer2Visible, layer2Opacity]);

  useEffect(() => {
    let isCurrent = true;
    const fetchData = () => {
      const formattedUrl = collectionUrl.replace(/\/\s*$/, "");
      getCollectionItems(`${formattedUrl}/items`).then(response => {
        if (isCurrent) {
          setCollectionItems(some(response.data));
          let items = getSourceItems(response.data);
          setSourceImages(items);
        }
      });
    };
    fetchData();
    return () => {
      isCurrent = false;
    };
  }, [collection, collectionUrl]);

  return (
    <StyledSidebar
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      pb={2}
      px={3}
      bg="white"
      isClosed={!isOpen}
      boxShadow={1}
      width="32rem"
      flex="none"
      zIndex="2"
    >
      <StyledHeader
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        mb={2}
        bg="inherit"
      >
        <Brand title="STAC Viewer" color="black" href="/" />
        {visibilityButton}
      </StyledHeader>
      <Field
        label="Collection"
        htmlFor="collection"
        showLabel={true}
        visible={true}
        showEye={false}
        onVisibilityChange={(b: boolean) => {}}
        mb={3}
      >
        <TextInput
          id="collection"
          type="url"
          placeholder="http://"
          value={collectionUrl}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCollectionUrl(e.target.value)}
        />
      </Field>
      {collectionUrl && (
        <>
          <Field
            label="Scene"
            showLabel={true}
            htmlFor="scene"
            visible={true}
            showEye={false}
            onVisibilityChange={(b: boolean) => {}}
            mb={4}
          >
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSourceSelect(e.target.value)}
              id="scene"
            >
              <option>Select a STAC item</option>
              {sourceItems.map((item, index) => (
                <option key={index} value={index}>
                  {item.id}
                </option>
              ))}
            </Select>
          </Field>

          {showSelectAsset && (
            <Field
              label="Image"
              showLabel={true}
              htmlFor="image"
              visible={true}
              showEye={false}
              onVisibilityChange={(b: boolean) => {}}
              mb={4}
            >
              <Select
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onAssetSelect(e.target.value)
                }
                id="image"
                defaultValue="0"
              >
                <option>Select an Image</option>
                {sourceAssets.map((item, index) => (
                  <option key={index} value={index}>
                    {item.id}
                  </option>
                ))}
              </Select>
            </Field>
          )}

          {showBandSelect && (
            <div>
              {bandSelector("Red Band", "redBand", setRedBand, redBand.toString())}
              {bandSelector("Green Band", "greenBand", setGreenBand, greenBand.toString())}
              {bandSelector("Blue Band", "blueBand", setBlueBand, blueBand.toString())}
            </div>
          )}

          <Field
            showLabel={true}
            label={showSlider ? "Left" : "Top"}
            htmlFor="layer"
            visible={layer1Visible}
            showEye={true}
            onVisibilityChange={v => setLayer1Visible(v)}
            mb={4}
          >
            {addRangeInput(setLayer1Opacity)}
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                aLayerSelect(viewableItems[+e.target.value])
              }
              id="layer1"
            >
              <option>Select an Item to View</option>
              {viewableItems.map((item, index) => (
                <option key={index} value={index}>
                  {item.id}
                </option>
              ))}
            </Select>
          </Field>
          <Field
            showLabel={true}
            label={showSlider ? "Right" : "Bottom"}
            htmlFor="layer2"
            visible={layer2Visible}
            showEye={true}
            onVisibilityChange={v => setLayer2Visible(v)}
            mb={4}
          >
            {addRangeInput(setLayer2Opacity)}
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                bLayerSelect(viewableItems[+e.target.value])
              }
              id="layer2"
            >
              <option>Select an Item to View</option>
              {viewableItems.map((item, index) => (
                <option key={index} value={index}>
                  {item.id}
                </option>
              ))}
            </Select>
          </Field>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Checkbox
                id="sliderToggle"
                mr="0.4rem"
                checked={showSlider}
                onChange={() => setShowSlider(!showSlider)}
                checkedColor="gray700"
                uncheckedColor="gray700"
                disabled={!layer1Visible || !layer2Visible}
              />{" "}
              <Label
                htmlFor="sliderToggle"
                color={layer1Visible && layer2Visible ? "gray700" : "gray400"}
              >
                Slider
              </Label>
            </Box>
          </Box>
          <Divider my={4} />
          {getOrElse(() => <></>)(
            map((item: StacItem) => (
              <Legend
                item={item}
                showSlider={showSlider}
                splitToText={(b: boolean) => (b ? "Left Legend" : "Top Legend")}
              />
            ))(aLayerItem)
          )}
          {getOrElse(() => <></>)(
            map((item: StacItem) => (
              <Legend
                item={item}
                showSlider={showSlider}
                splitToText={(b: boolean) => (b ? "Right Legend" : "Bottom Legend")}
              />
            ))(bLayerItem)
          )}
        </>
      )}
    </StyledSidebar>
  );
}

export default Sidebar;
