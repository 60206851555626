import React, { useState, useEffect, useRef } from "react";
import { StacItem } from "../stac/StacItem";
import { Option, option, none } from "fp-ts/es6/Option";
import MapboxGL, { LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import MapCompare from "mapbox-gl-compare";
import { Box } from "@blasterjs/core";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import CSS from "csstype";
import {
  addPlaceholderLayers,
  addLayerToMap,
  adjustOpacity,
  getTileUrlForItem
} from "../utils/map";
import { MapMetadata, MapBands } from "../model";

interface Props {
  aLayerOpacity: number;
  bLayerOpacity: number;
  aLayerItem: Option<StacItem>;
  bLayerItem: Option<StacItem>;
  sourceItem: Option<StacItem>;
  mapboxToken: string;
  center: LngLatLike;
  setCenter: (c: LngLatLike) => void;
  zoom: number;
  setZoom: (z: number) => void;
  zoomToSource: boolean;
  setZoomToSource: (b: boolean) => void;
  mapBands: MapBands;
  sourceAssetId: Option<string>;
}

const styles: CSS.Properties = {
  width: "100%",
  height: "100%",
  position: "absolute"
};

function CompareViewport(props: Props) {
  const compareContainer = useRef<HTMLElement | null>(null);
  const mapContainerA = useRef<HTMLElement | null>(null);
  const mapContainerB = useRef<HTMLElement | null>(null);

  const [mapA, setMapA] = useState<MapboxGL.Map | null>(null);
  const [mapB, setMapB] = useState<MapboxGL.Map | null>(null);
  const [mapMetadataA, setMapMetadataA] = useState(option.zero<MapMetadata>());
  const [mapMetadataB, setMapMetadataB] = useState(option.zero<MapMetadata>());

  const [compare, setCompare] = useState<MapboxGL.Map | null>(null);
  const [mapMetadataSource, setMapMetadataSource] = useState(option.zero<MapMetadata>());

  const setZoomToSource = props.setZoomToSource;
  const setCenter = props.setCenter;
  const setZoom = props.setZoom;

  // initialize map
  useEffect(() => {
    if (
      !mapA &&
      mapContainerA.current &&
      !mapB &&
      mapContainerB.current &&
      !compare &&
      compareContainer.current
    ) {
      if (mapContainerA.current && mapContainerB.current && compareContainer.current) {
        const mapA = new MapboxGL.Map({
          container: mapContainerA.current,
          accessToken: props.mapboxToken,
          style: "mapbox://styles/azavea/ck9lscmrf2jni1jmtixer0cl9",
          center: props.center,
          zoom: props.zoom
        });

        const mapB = new MapboxGL.Map({
          container: mapContainerB.current,
          accessToken: props.mapboxToken,
          style: "mapbox://styles/azavea/ck9lscmrf2jni1jmtixer0cl9",
          center: props.center,
          zoom: props.zoom
        });

        mapB.on("load", () => {
          setMapB(mapB);
          addPlaceholderLayers(mapB);
          option.map(mapMetadataSource, metadata =>
            option.map(props.bLayerItem, item =>
              addLayerToMap(mapB, metadata.url, "source", metadata.mapType, item)
            )
          );
        });

        mapA.on("load", () => {
          setMapA(mapA);
          addPlaceholderLayers(mapA);
          option.map(mapMetadataSource, metadata =>
            option.map(props.sourceItem, item =>
              addLayerToMap(mapA, metadata.url, "source", metadata.mapType, item)
            )
          );
        });

        // Track center and zoom changes when switching modes
        mapB.on("moveend", () => {
          let center = mapB.getCenter();
          setZoomToSource(false);
          setCenter(center);
        });

        mapB.on("zoomend", () => {
          let zoom = mapB.getZoom();
          setZoomToSource(false);
          setZoom(zoom);
        });

        const compare = new MapCompare(mapA, mapB, compareContainer.current);
        setCompare(compare);
      }
    }
  }, [
    mapA,
    mapB,
    compare,
    props.center,
    props.zoom,
    setCenter,
    setZoom,
    setZoomToSource,
    props.mapboxToken,
    mapMetadataSource,
    props.bLayerItem,
    props.aLayerItem,
    props.sourceItem
  ]);

  // Zoom map to ground truth image selected, maps are linked so only need to call on one
  useEffect(() => {
    function f(item: StacItem) {
      const bounds: LngLatBoundsLike = [item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]];
      return mapB?.fitBounds(bounds, { padding: 20 });
    }
    if (mapB && props.zoomToSource) {
      option.map(props.sourceItem, f);
    }
  }, [mapB, props.sourceItem, props.zoomToSource]);

  // Add ground truth basemap to both map A and map B
  useEffect(() => {
    getTileUrlForItem(props.sourceItem, setMapMetadataSource, props.sourceAssetId, props.mapBands);
  }, [props.sourceItem, props.mapBands, props.sourceAssetId]);

  useEffect(() => {
    mapB &&
      option.map(mapMetadataB, metadata =>
        option.map(props.bLayerItem, item =>
          addLayerToMap(mapB, metadata.url, "layerB", metadata.mapType, item)
        )
      );
  }, [mapB, mapMetadataB, props.bLayerItem]);

  useEffect(() => {
    mapA &&
      option.map(mapMetadataA, metadata =>
        option.map(props.aLayerItem, item =>
          addLayerToMap(mapA, metadata.url, "layerA", metadata.mapType, item)
        )
      );
  }, [mapA, mapMetadataA, props.aLayerItem]);

  useEffect(() => {
    mapA &&
      mapB &&
      option.map(mapMetadataSource, metadata => {
        option.map(props.sourceItem, item =>
          addLayerToMap(mapA, metadata.url, "source", metadata.mapType, item)
        );
        option.map(props.sourceItem, item =>
          addLayerToMap(mapB, metadata.url, "source", metadata.mapType, item)
        );
      });
  }, [mapA, mapB, mapMetadataSource, props.sourceItem]);

  // Add map A
  useEffect(() => {
    getTileUrlForItem(props.aLayerItem, setMapMetadataA, none, props.mapBands);
  }, [props.aLayerItem, props.mapBands]);

  // Add map B
  useEffect(() => {
    getTileUrlForItem(props.bLayerItem, setMapMetadataB, none, props.mapBands);
  }, [props.bLayerItem, props.mapBands]);

  // adjust opacity
  useEffect(() => {
    if (mapA) adjustOpacity(mapA, props.aLayerOpacity, "layerA");
    if (mapB) adjustOpacity(mapB, props.bLayerOpacity, "layerB");
  }, [mapA, mapB, props.aLayerOpacity, props.bLayerOpacity]);

  return (
    <Box
      display="flex"
      ref={(el: HTMLDivElement) => (compareContainer.current = el)}
      zIndex={1}
      flex="none"
    >
      <div style={styles} ref={(el: HTMLDivElement) => (mapContainerA.current = el)}></div>
      <div style={styles} ref={(el: HTMLDivElement) => (mapContainerB.current = el)}></div>
    </Box>
  );
}

export default CompareViewport;
