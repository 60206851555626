import { StacCollection } from "../stac/StacCollection";
import { StacTilesDescription } from "../stac/StacTilesDescription";
import { StacItemFeatureCollection } from "../stac/StacItemFeatureCollection";
import axios, { AxiosResponse } from "axios";

export const getCollection: (collectionUrl: string) => Promise<AxiosResponse<StacCollection>> = (
  collectionUrl: string
) => axios.get(collectionUrl);

export const getCollectionItems: (
  collectionItemsUrl: string
) => Promise<AxiosResponse<StacItemFeatureCollection>> = (collectionItemsUrl: string) =>
  axios.get(collectionItemsUrl);

export const getTilesLink: (tilesUrl: string) => Promise<AxiosResponse<StacTilesDescription>> = (
  tilesUrl: string
) => axios.get(tilesUrl);
