import React from "react";
import { Blaster } from "@blasterjs/core";
import MapPage from "./pages/MapPage";

function App() {
  return (
    <Blaster>
      <MapPage></MapPage>
    </Blaster>
  );
}

export default App;
