import { TwoDimBbox } from "./Bbox";
import { StacItemAsset } from "./StacItemAsset";
import { StacLink } from "./StacLink";

import { Option } from "fp-ts/es6/Option";
import { findFirst } from "fp-ts/es6/Array";

type Geometry = "hello";

export interface StacItem {
  readonly id: string;
  readonly stacVersion: string;
  readonly stacExtensions: Array<string>;
  readonly type: string;
  readonly geometry: Geometry;
  readonly bbox: TwoDimBbox;
  readonly links: Array<StacLink>;
  readonly assets: Map<string, StacItemAsset>;
  readonly collection: Option<string>;
  readonly properties: Record<string, any>;
}

export interface StacItemAssetWrapper {
  id: string;
  asset: StacItemAsset;
}

export function getCogAssets(item: StacItem): Array<StacItemAssetWrapper> {
  return Object.entries(item.assets)
    .map(([key, value]) => {
      return { id: key, asset: value };
    })
    .filter(wrapper => {
      let cogType = "image/vnd.stac.geotiff; cloud-optimized=true";
      return cogType === wrapper.asset.type;
    });
}

export function getItemTilesLink(item: StacItem) {
  function f(l: StacLink) {
    let isSame = l.rel === "tiles";
    return isSame;
  }
  return findFirst((l: StacLink) => f(l))(item.links);
}

export function getItemDataCollection(item: StacItem) {
  return findFirst((a: StacItemAsset) => a.roles.includes("data-collection"))(
    Object.values(item.assets)
  );
}
