import React, { useState, useEffect, useRef } from "react";
import { StacItem } from "../stac/StacItem";
import { Option, option, fromNullable, none } from "fp-ts/es6/Option";
import MapboxGL, { LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import { Box } from "@blasterjs/core";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import CSS from "csstype";
import {
  addPlaceholderLayers,
  addLayerToMap,
  adjustOpacity,
  getTileUrlForItem
} from "../utils/map";
import { MapMetadata, MapBands } from "../model";

interface Props {
  aLayerOpacity: number;
  bLayerOpacity: number;
  aLayerItem: Option<StacItem>;
  bLayerItem: Option<StacItem>;
  sourceItem: Option<StacItem>;
  mapboxToken: string;
  center: LngLatLike;
  setCenter: (c: LngLatLike) => void;
  zoom: number;
  setZoom: (z: number) => void;
  zoomToSource: boolean;
  setZoomToSource: (b: boolean) => void;
  mapBands: MapBands;
  sourceAssetId: Option<string>;
}

const styles: CSS.Properties = {
  width: "100%",
  height: "100%",
  position: "absolute"
};

function Viewport(props: Props) {
  const mapContainer = useRef<HTMLElement | null>(null);

  const [map, setMap] = useState<MapboxGL.Map | null>(null);
  const [mapLoaded, setMapLoadStatus] = useState(false);
  const [mapMetadataA, setMapMetadataA] = useState(option.zero<MapMetadata>());
  const [mapMetadataB, setMapMetadataB] = useState(option.zero<MapMetadata>());

  const [mapMetadataSource, setMapMetadataSource] = useState(option.zero<MapMetadata>());

  // Re-assigning to make react happy about dependency arrays below
  const setZoomToSource = props.setZoomToSource;
  const setCenter = props.setCenter;
  const setZoom = props.setZoom;

  // Add ground truth basemap to both map A and map B
  useEffect(() => {
    getTileUrlForItem(props.sourceItem, setMapMetadataSource, props.sourceAssetId, props.mapBands);
    function f(item: StacItem) {
      const bounds: LngLatBoundsLike = [item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]];
      return map?.fitBounds(bounds, { padding: 20 });
    }
    if (map && props.zoomToSource) {
      option.map(props.sourceItem, f);
    }
  }, [map, props.sourceItem, props.zoomToSource, props.mapBands, props.sourceAssetId]);

  useEffect(() => {
    function initializeMap() {
      if (!map && mapContainer.current) {
        const map = new MapboxGL.Map({
          container: mapContainer.current,
          accessToken: props.mapboxToken,
          style: "mapbox://styles/azavea/ck9lscmrf2jni1jmtixer0cl9",
          center: props.center,
          zoom: props.zoom
        });

        map.on("load", () => {
          addPlaceholderLayers(map);
          setMapLoadStatus(true);
        });
        return map;
      }
    }

    if (!map && mapContainer.current) {
      let initializedMapOption = fromNullable(initializeMap());
      option.map(initializedMapOption, initializedMap => {
        setMap(initializedMap);
        initializedMap.on("moveend", () => {
          let center = initializedMap.getCenter();
          if (center) {
            setCenter(center);
            setZoomToSource(false);
          }
        });

        initializedMap.on("zoomend", () => {
          let zoom = initializedMap.getZoom();
          setZoomToSource(false);
          setZoom(zoom);
        });
      });
    }
  }, [map, props.mapboxToken, props.center, props.zoom, setCenter, setZoom, setZoomToSource]);

  // Add map A
  useEffect(() => {
    getTileUrlForItem(props.aLayerItem, setMapMetadataA, none, props.mapBands);
  }, [props.aLayerItem, props.mapBands]);

  // Add map B
  useEffect(() => {
    getTileUrlForItem(props.bLayerItem, setMapMetadataB, none, props.mapBands);
  }, [props.bLayerItem, props.mapBands]);

  useEffect(() => {
    map &&
      mapLoaded &&
      option.map(mapMetadataB, metadata =>
        option.map(props.bLayerItem, item =>
          addLayerToMap(map, metadata.url, "layerB", metadata.mapType, item)
        )
      );
  }, [map, mapLoaded, mapMetadataB, props.bLayerItem]);

  useEffect(() => {
    map &&
      mapLoaded &&
      option.map(mapMetadataA, metadata =>
        option.map(props.aLayerItem, item =>
          addLayerToMap(map, metadata.url, "layerA", metadata.mapType, item)
        )
      );
  }, [map, mapLoaded, mapMetadataA, props.aLayerItem]);

  useEffect(() => {
    map &&
      mapLoaded &&
      option.map(mapMetadataSource, metadata =>
        option.map(props.sourceItem, item =>
          addLayerToMap(map, metadata.url, "source", metadata.mapType, item)
        )
      );
  }, [map, mapLoaded, mapMetadataSource, props.sourceItem]);

  // adjust opacity
  useEffect(() => {
    if (map) {
      adjustOpacity(map, props.aLayerOpacity, "layerA");
      adjustOpacity(map, props.bLayerOpacity, "layerB");
    }
  }, [map, props.aLayerOpacity, props.bLayerOpacity]);

  return (
    <Box
      display="flex"
      ref={(el: HTMLDivElement) => (mapContainer.current = el)}
      zIndex={1}
      flex="none"
      style={styles}
    />
  );
}

export default Viewport;
