import React, { useState } from "react";
import { Box } from "@blasterjs/core";
import Sidebar from "../components/Sidebar";
import { LngLatLike } from "mapbox-gl";
import CompareViewport from "../components/CompareViewport";
import Viewport from "../components/Viewport";
import { option, some } from "fp-ts/es6/Option";
import { StacItem } from "../stac/StacItem";
import { MapBands } from "../model";
interface Props {}

function MapPage(props: Props) {
  const [layerItemA, setLayerItemA] = useState(option.zero<StacItem>());
  const [layerItemB, setLayerItemB] = useState(option.zero<StacItem>());
  const [sourceItem, setSourceItem] = useState(option.zero<StacItem>());
  const [center, setCenter] = useState<LngLatLike>({ lng: 0, lat: 0 });
  const [zoom, setZoom] = useState(2);
  const [showSlider, setShowSlider] = useState(true);
  const [aLayerOpacity, setALayerOpacity] = useState(100);
  const [bLayerOpacity, setBLayerOpacity] = useState(100);
  const [zoomToSource, setZoomToSource] = useState(true);
  const [sourceAssetId, setSourceAssetId] = useState(option.zero<string>());
  const [mapBands, setMapBands] = useState<MapBands>({ redBand: 3, greenBand: 2, blueBand: 1 });
  const selectALayerOpacity = (i: number) => setALayerOpacity(i);
  const selectBLayerOpacity = (i: number) => setBLayerOpacity(i);
  const selectItem = (i: StacItem) => setLayerItemA(some(i));
  const selectSource = (i: StacItem) => {
    setZoomToSource(true);
    setSourceItem(some(i));
  };
  const selectItemB = (i: StacItem) => setLayerItemB(some(i));
  const toggleSlider = (b: boolean) => setShowSlider(b);

  return (
    <Box display="flex" flexDirection="row" alignItems="stretch" height="100%" {...props}>
      <Sidebar
        aLayerSelect={selectItem}
        bLayerSelect={selectItemB}
        selectedItem={selectSource}
        aLayerOpacity={selectALayerOpacity}
        bLayerOpacity={selectBLayerOpacity}
        showSlider={showSlider}
        setShowSlider={toggleSlider}
        aLayerItem={layerItemA}
        bLayerItem={layerItemB}
        setMapBands={setMapBands}
        setSourceAssetId={setSourceAssetId}
      />

      {showSlider ? (
        <CompareViewport
          aLayerOpacity={aLayerOpacity}
          bLayerOpacity={bLayerOpacity}
          aLayerItem={layerItemA}
          bLayerItem={layerItemB}
          sourceItem={sourceItem}
          mapboxToken={
            "pk.eyJ1IjoiYXphdmVhIiwiYSI6ImNrOXgxODZzcjAxOGEzZm8zZG5qaDFtY3YifQ.xDMhwTbQpzbdHne0_NiLwQ"
          }
          center={center}
          setCenter={setCenter}
          zoom={zoom}
          setZoom={setZoom}
          zoomToSource={zoomToSource}
          setZoomToSource={setZoomToSource}
          mapBands={mapBands}
          sourceAssetId={sourceAssetId}
        />
      ) : (
        <Viewport
          aLayerOpacity={aLayerOpacity}
          bLayerOpacity={bLayerOpacity}
          aLayerItem={layerItemA}
          bLayerItem={layerItemB}
          sourceItem={sourceItem}
          mapboxToken={
            "pk.eyJ1IjoiYXphdmVhIiwiYSI6ImNrOXgxODZzcjAxOGEzZm8zZG5qaDFtY3YifQ.xDMhwTbQpzbdHne0_NiLwQ"
          }
          center={center}
          setCenter={setCenter}
          zoom={zoom}
          setZoom={setZoom}
          zoomToSource={zoomToSource}
          setZoomToSource={setZoomToSource}
          mapBands={mapBands}
          sourceAssetId={sourceAssetId}
        />
      )}
    </Box>
  );
}

export default MapPage;
