import React from "react";
import { Box, Label, Button, Icon } from "@blasterjs/core";

interface FieldProps {
  htmlFor: string;
  label: string;
  visible: boolean;
  onVisibilityChange: (b: boolean) => void;
  children: JSX.Element[] | JSX.Element;
  mb: number;
  showEye: boolean;
  showLabel: boolean;
}

function Field({
  htmlFor,
  label,
  visible,
  onVisibilityChange,
  children,
  mb,
  showEye,
  showLabel,
  ...props
}: FieldProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-start"
      mb={mb}
      {...props}
    >
      {showLabel && (
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          pr={1}
        >
          <Label
            htmlFor={htmlFor}
            color={visible === false ? "gray400" : "gray700"}
            fontWeight="bold"
          >
            {label}
          </Label>
          {showEye && visible !== undefined && (
            <Button appearance="minimal" p={0} onClick={() => onVisibilityChange(!visible)}>
              <Icon name={visible ? "eye" : "eyeOff"} color="gray500" />
            </Button>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
}

export default Field;
