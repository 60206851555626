import React from "react";
import { StacItem, getItemTilesLink, getItemDataCollection } from "../stac/StacItem";
import { getCollection } from "../http/stac";
import { getTilesLink } from "../http/stac";
import { Option, option, map, some, fromNullable, getOrElse } from "fp-ts/es6/Option";
import { head, findFirst } from "fp-ts/es6/Array";
import MapboxGL, { RasterSource, VectorSource, Layer } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import { StacItemAsset } from "../stac/StacItemAsset";
import { StacLink } from "../stac/StacLink";
import { pipe } from "fp-ts/es6/pipeable";
import { MapMetadata, MapBands } from "../model";

// This function is used to prepopulate layers in the correct
// display order. As layers are edited/changed these get replaced
// but it is easiest if they exist already so we can start from
// the correct order
export function addPlaceholderLayers(map: MapboxGL.Map) {
  map.addSource("empty", {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  });

  map.addLayer({
    id: "empty",
    type: "symbol",
    source: "empty"
  });

  map.addLayer(
    {
      id: "layerA",
      type: "symbol",
      source: "empty"
    },
    "empty"
  );

  map.addLayer(
    {
      id: "layerB",
      type: "symbol",
      source: "empty"
    },
    "layerA"
  );

  map.addLayer(
    {
      id: "source",
      type: "symbol",
      source: "empty"
    },
    "layerB"
  );
}

// Adds a layer to a map with a given name
export function addLayerToMap(
  map: MapboxGL.Map,
  tileUrl: string,
  name: string,
  mapType: "TMS" | "MVT",
  layerItem: StacItem
) {
  // Empty Layer
  // Layer A = Left/Top
  // Layer B = Right/Bottom
  // Source = Imagery
  let beforeId = "empty";
  if (name === "source") {
    beforeId = "layerB";
  } else if (name === "layerA") {
    beforeId = "empty";
  } else if (name === "layerB") {
    beforeId = "layerA";
  }

  let mapSource: RasterSource | VectorSource =
    mapType === "TMS"
      ? {
          type: "raster",
          tiles: [tileUrl]
        }
      : {
          type: "vector",
          tiles: [tileUrl]
        };

  let mapLayer: Layer =
    mapType === "TMS"
      ? {
          id: name,
          source: name,
          type: "raster"
        }
      : {
          id: name,
          source: name,
          type: "fill",
          "source-layer": "default",
          paint: {
            "fill-color": ["get", "color"]
          }
        };

  if (map.getLayer(name)) map.removeLayer(name);
  if (map.getSource(name)) map.removeSource(name);
  map.addSource(name, mapSource);
  map.addLayer(mapLayer, beforeId);
}

// Adjusts map opacity given a number betwenn 0 and 100
export function adjustOpacity(map: MapboxGL.Map, opacity: number, name: string) {
  let layer = map.getLayer(name);

  if (layer?.type === "raster") {
    map.setPaintProperty(name, "raster-opacity", opacity / 100);
  } else if (layer?.type === "fill") {
    map.setPaintProperty(name, "fill-opacity", opacity / 100);
  }
}

const getDataCollectionAssetTile: (
  asset: StacItemAsset,
  colorField: string,
  callback: (value: React.SetStateAction<Option<MapMetadata>>) => void
) => Promise<void> | void = (asset, colorField, callback) => {
  getCollection(asset.href).then(response => {
    let collection = response.data;
    let collectionTiles = findFirst((l: StacLink) => l.rel === "tiles")(collection.links);
    pipe(
      collectionTiles,
      map(link => link.href),
      map(href => getTilesLink(href)),
      map(promise =>
        promise.then(response => {
          let tiles = response.data;
          pipe(
            head(tiles.links),
            map(l => updateCollectionTileString(l.href, colorField)),
            map(href => callback(some({ url: href, mapType: "MVT" })))
          );
        })
      )
    );
  });
};

// Make HTTP request for tile URL and update state via callback
export function getTileUrlForItem(
  item: Option<StacItem>,
  setFunction: (value: React.SetStateAction<Option<MapMetadata>>) => void,
  assetId: Option<string>,
  mapBands: MapBands
) {
  let dataCollection: Option<StacItemAsset> = option.chain(item, getItemDataCollection);
  let tilesLink = option.chain(item, getItemTilesLink);
  let colorField = getOrElse(() => "color")(
    option.chain(item, item => fromNullable(item.properties["label:color_field"]))
  );
  const mvtLayerSet = option.map(dataCollection, collection =>
    getDataCollectionAssetTile(collection, colorField, setFunction)
  );

  option.alt(mvtLayerSet, () =>
    option.map(tilesLink, async link => {
      const response = await getTilesLink(link.href);
      let assetTileLink: Option<StacLink> = pipe(
        option.map(assetId, (id: string) =>
          findFirst((l: StacLink) => l.href.includes(encodeURIComponent(id).replace(/%20/g, "+")))(
            response.data.links
          )
        ),
        getOrElse(() => head(response.data.links))
      );
      option.map(
        updateTileString(
          item,
          option.map(assetTileLink, l => l.href),
          mapBands
        ),
        href => setFunction(some({ url: href, mapType: "TMS" }))
      );
    })
  );
}

function updateCollectionTileString(href: string, colorField: string): string {
  return href.replace(
    "{tileMatrixSetId}/{tileMatrix}/{tileCol}/{tileRow}",
    `WebMercatorQuad/{z}/{x}/{y}?colorField=${colorField}`
  );
}

// format item tile URL based on if item is a source or label raster
function updateTileString(
  itemOption: Option<StacItem>,
  hrefOption: Option<string>,
  mapBands: MapBands
): Option<string> {
  return option.chain(itemOption, item => {
    let isLabel = item.links.find(l => l.rel === "source");
    return option.map(hrefOption, href => {
      let zxy = href.replace(
        "{tileMatrixSetId}/{tileMatrix}/{tileCol}/{tileRow}",
        "WebMercatorQuad/{z}/{x}/{y}"
      );
      if (isLabel) {
        // if it's the label, assume it's single band
        return `${zxy}&singleBand=0`;
      } else {
        // if it's the source, assume 3 bands
        return `${zxy}&redBand=${mapBands.redBand}&greenBand=${mapBands.greenBand}&blueBand=${mapBands.blueBand}&lowerQuantile=2&upperQuantile=98`;
      }
    });
  });
}
